<template>
  <div>
    <titleBar
      :title="'Messaging'"
      :img="bot?.avatar"
      back="auto"
      :showBackArrowDesktop="true"
      subtitle=""
      :centered="false"
      theme="white"
      :showSignup="false"
      containerClass="donotuse"
      :showDesktop="true"
      :showAfterScroll="0"
      :scrollTopOnClick="true"
      :inline="false"
      actionButtonStyle="round"
      actionButtonRoundBg="transparent"
      iconPack="fas"
    />

    <div class="sectionNA">
      <div class="containerNA NHAw800">
        <b-field horizontal label=" ">
          <header>
            <h2 class="title is-2">
              <span>Messaging ✉️</span>
            </h2>
          </header>
        </b-field>
        <hr />

        <!-- START-->

        <b-field horizontal>
          <h3 class="title is-4">Messaging</h3>
        </b-field>

        <b-field horizontal>
          <b-switch v-model="bot.enableMessaging" type="is-info">
            Enable AI chat for this page
            <b-tooltip
              type="is-dark"
              multilined
              label="Your chatbot will be able to receive messages from users, and chat with them."
            >
              <i class="fal fa-question-circle" aria-hidden="true"></i>
            </b-tooltip>
          </b-switch>
        </b-field>

        <div v-if="bot.enableMessaging">
          <!-- Drop down for messagingMode. Options: Simple, Multi, Advanced, Custom model. All options except Simple are disabled.  -->
          <b-field horizontal label="Mode">
            <b-select v-model="bot.messagingMode" placeholder="Select a mode" :disabled="posting">
              <option value="simple">Simple chat</option>
              <option value="api" disabled>API Driven</option>
              <option value="multi" disabled>Multi</option>

              <option value="custom" disabled>Custom Models</option>
              <option value="advanced" disabled>Advanced</option>
            </b-select>
          </b-field>

          <b-field horizontal label="Behavior">
            <b-input
              type="textarea"
              placeholder="Utility function that translate user messages to emojis. No dialogue, just emojis output."
              v-model="bot.systemMsg"
              :loading="posting"
              :disabled="posting"
            ></b-input>
          </b-field>

          <b-field horizontal label="Opener">
            <b-input
              type="textarea"
              placeholder="Optional. Static message users receive when oppening the chat. "
              v-model="bot.defaultOpenerMsg"
              :loading="posting"
              :disabled="posting"
            ></b-input>
          </b-field>

          <b-field horizontal label="example msg">
            <b-input
              size="is-"
              placeholder="optional.  My cat loves pizza"
              v-model="bot.exampleMsg"
              :loading="posting"
              :disabled="posting"
            ></b-input>
          </b-field>

          <b-field horizontal label="example reply">
            <b-input
              size="is-"
              placeholder="optional.  😻🍕."
              v-model="bot.exampleResply"
              :loading="posting"
              :disabled="posting"
            ></b-input>
          </b-field>

          <!-- 
            max number
        -->
          <b-field horizontal label="Max replies">
            <b-input
              style="max-width: 200px"
              size="is-"
              placeholder=" 5"
              v-model="bot.maxMsg"
              :loading="posting"
              type="number"
              :disabled="posting"
            ></b-input>
          </b-field>
          <br />

          <b-field horizontal label="Engine">
            <b-select
              v-if="bot.messagingMode == 'simple'"
              v-model="bot.messagingEngine"
              placeholder="Select engine"
              :disabled="posting"
            >
              <option value="gpt3">Default (speed-optimized GPT3.5)</option>
              <option value="gpt4">Smart (slower & pricier GPT4)</option>
              <!--  
              <option value="" disabled>Other (soon)</option>-->
            </b-select>
          </b-field>
          <br />

          <b-field horizontal label="Creativity">
            <b-slider v-model="bot.creativity" :min="1" style="max-width: 200px"></b-slider>
          </b-field>

          <b-field horizontal label="Formating options">
            <b-field label="Chatb bubllbes  color">
              <b-colorpicker v-model="bot.bubbleColor1" :alpha="false" />
            </b-field>

            <div>
              <b-switch v-model="bot.markdownResponsesEnabled" type="is-info">
                Enable markdown
                <b-tooltip type="is-dark" multilined label="The bot responses are formated">
                  <i class="fal fa-question-circle" aria-hidden="true"></i>
                </b-tooltip>
              </b-switch>
              <br />

              <b-switch v-model="bot.formatCodeblocks" type="is-info">
                Code blocks
                <b-tooltip type="is-dark" multilined label=" Get those pretty code blocks.">
                  <i class="fal fa-question-circle" aria-hidden="true"></i>
                </b-tooltip>
                <br />
              </b-switch>
              <br />

              <b-switch v-model="bot.formatBoldHighlight" type="is-info">
                Proofreading
                <b-tooltip type="is-dark" multilined label="Highlight bold text">
                  <i class="fal fa-question-circle" aria-hidden="true"></i>
                </b-tooltip>
                <br />
              </b-switch>
              <br />
            </div>
          </b-field>
          <br />

          <b-field horizontal label="Variables">
            <div>
              <b-switch v-model="bot.accessDate" type="is-info">
                Date
                <b-tooltip type="is-dark" multilined label="Add more context to your responses.">
                  <i class="fal fa-question-circle" aria-hidden="true"></i>
                </b-tooltip> </b-switch
              ><br />
              <b-switch v-model="bot.accessTime" type="is-info">
                Local time
                <b-tooltip type="is-dark" multilined label="Add more context to your responses.">
                  <i class="fal fa-question-circle" aria-hidden="true"></i>
                </b-tooltip>
                <span class="tag is-warning is-light">Premium</span> </b-switch
              ><br />

              <b-switch v-model="bot.accessUserPronoun" type="is-info">
                Local time
                <b-tooltip type="is-dark" multilined label="Add more context to your responses.">
                  <i class="fal fa-question-circle" aria-hidden="true"></i>
                </b-tooltip>
                <span class="tag is-warning is-light">Premium</span> </b-switch
              ><br />

              <b-switch v-model="bot.accessIp" type="is-info">
                User IP
                <b-tooltip type="is-dark" multilined label="Personalize responses.">
                  <i class="fal fa-question-circle" aria-hidden="true"></i>
                </b-tooltip>
                <span class="tag is-warning is-light">Premium</span> </b-switch
              ><br />

              <b-switch v-model="bot.accessCountry" type="is-info">
                User Country
                <b-tooltip type="is-dark" multilined label="Add more context to your responses.">
                  <i class="fal fa-question-circle" aria-hidden="true"></i>
                </b-tooltip>
                <span class="tag is-warning is-light">Premium</span> </b-switch
              ><br />
              <b-switch v-model="bot.accessLang" type="is-info">
                User Language
                <b-tooltip type="is-dark" multilined label="Add more context to your responses.">
                  <i class="fal fa-question-circle" aria-hidden="true"></i>
                </b-tooltip>
                <span class="tag is-warning is-light">Premium</span> </b-switch
              ><br />
            </div>
          </b-field>
          <br />

          <b-field horizontal label="User Disclaimer">
            <b-input
              size="is-"
              placeholder="optional. ex: Don't trust this bot advices."
              v-model="bot.disclaimer"
              :loading="posting"
              :disabled="posting"
            ></b-input>
          </b-field>

          <!-- series of checkbox for user permissions -->
          <!-- 

        <b-field horizontal label="Viewers">
          <b-taginput
            :disabled="bot.publicAccess"
            v-model="bot.viewers"
            ellipsis
            :placeholder="!bot.publicAccess ? 'Add by email' : '( anyone )'"
            aria-close-label="Delete this tag"
          >
          </b-taginput>
        </b-field>
        <b-field horizontal label="Editors">
          <b-taginput v-model="bot.editors" ellipsis placeholder="Add by email" aria-close-label="Delete this tag">
          </b-taginput>
        </b-field>
        <b-field horizontal label="Admins">
          <b-taginput v-model="bot.admins" ellipsis placeholder="Add by email" aria-close-label="Delete this tag">
          </b-taginput>
        </b-field>
         -->
        </div>

        <!-- END     
    -->
        <b-field horizontal label="   ">
          <b-button type="is-info is-outlined" @click="save" :loading="posting" :disabled="posting"
            >Save changes</b-button
          >
        </b-field>

        <hr />
      </div>
    </div>
  </div>
</template>

<script>
//import Editor from "@/components/e/EditorV";

export default {
  //name: "readmeEditor",
  components: {
    //Editor,
  },
  props: {
    bot: {
      type: Object,
      default: null,
    },
    posting: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // readmeJson: defaultReadme, // "432432432",
    };
  },
  methods: {
    save() {
      this.$emit("save");
    },
  },
};
</script>

<style></style>
